// html formatter
const htmlFormat = [
  { symbol: "*", tag: "b" },
  { symbol: "_", tag: "em" },
  { symbol: "~", tag: "del" },
  { symbol: "`", tag: "code" },
];

const markdownFormat = (text: any = "") => {
  const emailRegex = /\w+@\w+\.\w+/gi;
  const urlRegex =
    // eslint-disable-next-line
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
  htmlFormat.forEach(({ symbol, tag }) => {
    if (!text) return;

    const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, "gm");
    const match = text?.match(regex);
    if (!match) return;

    match.forEach((m: any) => {
      let formatted = m;
      for (let i = 0; i < 2; i++) {
        formatted = formatted.replace(symbol, `<${i > 0 ? "/" : ""}${tag}>`);
      }
      text = text?.replace(m, formatted);
    });
  });
  let formatText = text?.replace(emailRegex, (match: any) => {
    return `<a href="mailto:${match}" class="underline text-brand" rel="noopener noreferrer">${match}</a>`;
  });
  formatText = text?.replace(urlRegex, (match: any) => {
    let updatedMatch = "";
    if (match.indexOf("http://") !== 0 || match.indexOf("https://") !== 0) {
      updatedMatch = `http://${match}`;
    }
    return `<a href="${updatedMatch}" class="underline text-brand" rel="noopener noreferrer">${match}</a>`;
  });
  return formatText;
};

export default markdownFormat;
