import {
  defaultTemplateParams,
  defaultFormat,
  carouselDefaultTemplateParams,
} from "constants/DefaultValues";
import url from "helper/url";
import { cloneDeep, forEach } from "lodash";
import {
  Template,
  TemplateComponents,
  CarouselCardParameters,
  websiteInterface,
  CarouselTemplateRequiredParameters,
  CardsArray,
  cardsInterface,
} from "types";

export const Templates = {
  checkParamater: (t: Template) => {
    const count = cloneDeep(defaultTemplateParams);
    const expression = new RegExp(/{{[\d]{1,2}}}/gm, "g");
    let cardCount = 0;
    let ltoCount = 0;
    for (const i of t.components) {
      if (i.type === "HEADER") {
        switch (i.format) {
          case "IMAGE":
            count.header[0] = {
              type: "image",
              image: { link: "" },
              media: "",
              i,
            };
            break;
          case "VIDEO":
            count.header[0] = {
              type: "video",
              video: { link: "" },
              media: "",
              i,
            };
            break;
          case "DOCUMENT":
            count.header[0] = {
              type: "document",
              document: { link: "" },
              media: "",
              i,
            };
            break;
          case "TEXT":
            const length = i?.text?.match(expression)?.length as number;
            for (let i = 0; i < length; i++) {
              const isFound = count.header.find((c: any) => c && c.index === i);
              if (!isFound) {
                count.header[i] = { type: "text", text: "", i };
              }
            }
            break;
          default:
            break;
        }
      }
      if (i.type === "BODY") {
        const length = i?.text?.match(expression)?.length as number;
        for (let i = 0; i < length; i++) {
          const isFound = count.body.find((c: any) => c && c.index === i);
          if (!isFound) {
            count.body[i] = { type: "text", text: "", i };
          }
        }
      }
      if (i.type === "FOOTER") {
        const length = i?.text?.match(expression)?.length as number;
        for (let i = 0; i < length; i++) {
          const isFound = count.footer.find((c: any) => c && c.index === i);
          if (!isFound) {
            count.footer[i] = { type: "text", text: "", i };
          }
        }
      }
      if (i.type === "BUTTONS") {
        const length = i?.buttons?.length as number;
        for (let j = 0; j < length; j++) {
          const length = i.buttons[j]?.url?.match(expression)?.length as number;
          if (length !== undefined) {
            count.buttons[j] = {
              type: "text",
              text: "",
              i: j,
              sub_type: i?.buttons[j].type,
            };
          }
          if (i?.buttons[j].type == "COPY_CODE") {
            count.buttons[j] = {
              type: "text",
              text: "",
              i: j,
              sub_type: "COPY_CODE",
            };
          }
        }
      }
      if (i.type.toUpperCase() === "CAROUSEL") {
        i.cards !== undefined &&
          i.cards.forEach((card: any, index: number) => {
            card.components.forEach((v: any, j: number) => {
              if (v.type == "HEADER") {
                let header: any = {};
                switch (v.format) {
                  case "IMAGE":
                    header = {
                      type: "image",
                      image: { id: "" },
                    };
                    break;
                  case "VIDEO":
                    header = {
                      type: "video",
                      video: { id: "" },
                    };
                    break;
                  default:
                    break;
                }
                count.cards?.push({ header: header });
                cardCount++;
              }

              if (v.type == "BODY") {
                const length = v.text.match(expression)?.length as number;
                if (length > 0 && count.cards !== undefined) {
                  count.cards[index] = { body: [] };
                }
                for (let i = 0; i < length; i++) {
                  if (count.cards !== undefined) {
                    count.cards[index]["body"][i] = {
                      type: "text",
                      text: "",
                      i,
                    };
                    cardCount++;
                  }
                }
              }

              if (v.type === "BUTTONS" || v.type === "buttons") {
                const length = v?.buttons?.length as number;
                //const length = v?.buttonArray?.length as number;

                for (let j = 0; j < length; j++) {
                  const length = v.buttons[j]?.url?.match(expression)
                    ?.length as number;
                  if (count.cards !== undefined) {
                    if (length > 0) {
                      count.cards[index] = { buttons: [] };
                      count.cards[index]["buttons"][j] = {
                        type: "text",
                        text: "",
                        i: j,
                        sub_type: i?.buttons[j].type,
                      };
                    }
                    if (v?.buttons[j].type == "COPY_CODE") {
                      //count.buttons[j] = { type: "text", text: "", i:j,sub_type:'COPY_CODE' };
                      count.cards[index]["buttons"][j] = {
                        type: "text",
                        text: "",
                        i: j,
                        sub_type: "COPY_CODE",
                      };
                    }
                  }
                }
              }
            });
          });
      }
      if (i.type.toUpperCase() == "LIMITED_TIME_OFFER") {
        if (
          i.limited_time_offer &&
          i.limited_time_offer.has_expiration == true
        ) {
          count.lto_expiration_time = "";
          count.has_expiration = true;
          ltoCount++;
        }
      }
    }

    count.totalCount =
      Number(count.header.length) +
      Number(count.body.length) +
      Number(count.buttons.length) +
      Number(count.footer.length) +
      Number(ltoCount) +
      Number(cardCount);
    return count;
  },
  checkParamaterAndGetCount: (t: string) => {
    const expression = new RegExp(/{{[\d]{1,2}}}/gm, "g");
    const length = t?.match(expression)?.length as number;
    return length !== undefined ? length : 0;
  },
  setParameterValue: (value: any, template: Template, count: any) => {
    const cloneTemplate = cloneDeep(template);
    for (const component of cloneTemplate.components) {
      if (component.type.toUpperCase() == "HEADER" && count.header.length > 0) {
        switch (component.format) {
          case "image":
          case "IMAGE":
            component.image = { link: `${value?.header[0]?.media}` };
            break;
          case "video":
          case "VIDEO":
            component.video = { link: `${value?.header[0]?.media}` };
            break;
          case "document":
          case "DOCUMENT":
            component.document = { link: `${value?.header[0]?.media}` };
            break;
          case "text":
          case "TEXT":
            for (const [index, j] of value.header.entries()) {
              const i = index + 1;
              if (j.text.length > 0) {
                component.text = component?.text?.replace(
                  `{{${i}}}`,
                  value.header[index].text
                );
              }
            }
            break;
          default:
            break;
        }
      }
      if (
        (component.type === "BODY" || component.type === "body") &&
        count.body.length > 0
      ) {
        if (value && value.body) {
          for (const [index, j] of value.body.entries()) {
            const i = index + 1;
            if (j.text.length > 0) {
              component.text = component?.text?.replace(
                `{{${i}}}`,
                value.body[index].text
              );
            }
          }
        }
      }
      if (
        (component.type === "FOOTER" || component.type === "footer") &&
        count.footer.length > 0
      ) {
        if (value && value.footer) {
          for (const [index, j] of value.footer.entries()) {
            const i = index + 1;
            if (j.text.length > 0) {
              component.text = value?.text?.replace(
                `{{${i}}}`,
                value.body[index].text
              );
            }
          }
        }
      }

      if (
        component.type.toUpperCase() == "CAROUSEL" &&
        count.cards.length > 0
      ) {
        if (value && value.cards) {
          value.cards.forEach((card: any, cardIndex: number) => {
            if (card.body) {
              for (const [index, j] of card.body.entries()) {
                const i = index + 1;
                if (j.text.length > 0 && component.cards) {
                  component.cards[cardIndex].components.forEach(
                    (cardComponent: any) => {
                      if (
                        cardComponent.type == "BODY" ||
                        cardComponent.type == "body"
                      ) {
                        cardComponent.text = cardComponent?.text?.replace(
                          `{{${i}}}`,
                          j.text
                        );
                      }
                    }
                  );
                }
              }
            }
          });
        }
      }
    }
    return cloneTemplate;
  },
  /*   setComponentParameter: (formData: any, data: any) => {

    const header: any = { type: "header", parameters: [] };
    const body: any = { type: "body", parameters: [] };
    const footer: any = { type: "footer", parameters: [] };
    const components = [];
    if (data?.header.length > 0) {
      header.parameters = data?.header.map((h: any, index: any) => {
        const body: any = { type: h.type };
        if (h.type === "text") {
          body.text = formData.header[index].text;
        } else {
          body[h.type] = {
            link: formData?.header[index].media,
          };
          if (h.type === "document") {
            body[h.type] = {
              link: formData?.header[index].media,
              filename: url.getFilename(`${formData.header[index].media}`),
            };
          }
        }
        return body;
      });
      components.push(header);
    }
    if (data?.body.length > 0) {
      body.parameters = formData.body.map((h: any) => ({
        type: "text",
        text: h.text,
      }));
      components.push(body);
    }
    if (data?.footer.length > 0) {
      footer.parameters = formData?.footer.map((h: any) => ({
        type: "text",
        text: h.text,
      }));
      components.push(footer);
    }
    return components;
  }, */

  setComponentParameter: (formData: any, data: any) => {
    const header: any = { type: "header", parameters: [] };
    const body: any = { type: "body", parameters: [] };
    const footer: any = { type: "footer", parameters: [] };
    const buttons: any = {
      type: "BUTTON",
      sub_type: "",
      index: 0,
      parameters: [],
    };
    const components = [];
    if (data?.header.length > 0) {
      header.parameters = data?.header.map((h: any, index: any) => {
        const body: any = { type: h.type };
        if (h.type === "text") {
          body.text = formData.header[index].text;
        } else {
          body[h.type] = {
            link: formData?.header[index].media,
          };
          if (h.type === "document") {
            body[h.type] = {
              link: formData?.header[index].media,
              filename: url.getFilename(`${formData.header[index].media}`),
            };
          }
        }
        return body;
      });
      components.push(header);
    }
    if (data?.body.length > 0) {
      body.parameters = formData.body.map((h: any) => ({
        type: "text",
        text: h.text,
      }));
      components.push(body);
    }
    if (data?.footer.length > 0) {
      footer.parameters = formData?.footer.map((h: any) => ({
        type: "text",
        text: h.text,
      }));
      components.push(footer);
    }

    if (data?.buttons.length > 0) {
      formData?.buttons.map((h: any, i: number) => {
        if (data.buttons[i].sub_type == "COPY_CODE") {
          components.push({
            type: "button",
            sub_type: data.buttons[i].sub_type,
            index: i,
            parameters: [{ type: "coupon_code", coupon_code: h.text }],
          });
        } else {
          components.push({
            type: "button",
            sub_type: data.buttons[i].sub_type,
            index: i,
            parameters: [{ type: "text", text: h.text }],
          });
        }
        return;
      });
    }
    return components;
  },

  setComponentParameter2: (formData: any, data: any, template: any) => {
    const templateType = Templates.getTemplateType(JSON.stringify(template));
    const header: any = { type: "header", parameters: [] };
    const body: any = { type: "body", parameters: [] };
    const footer: any = { type: "footer", parameters: [] };
    // const buttons:any={ type: "BUTTON",sub_type:"",index:0, parameters: [] };
    console.log("templateType", templateType);
    console.log("formData", formData);
    console.log("data", data);
    console.log("template", template);
    const components: any = [];

    if (templateType.toUpperCase() == "CAROUSEL") {
      const cards: any = [];
      const body: any = { type: "BODY", parameters: [] };

      data.cards.forEach((v: any, index: number) => {
        const cardComp: any = [];
        const header: any = { type: "HEADER", parameters: [] };
        console.log("v>>>>", v);
        if (v.header.type == "image") {
          header.parameters.push({
            type: "IMAGE",
            image: {
              // "id": formData.header[index].media
              link: formData.header[index].media,
            },
          });
          cardComp.push(header);
        }
        if (v.header.type.toLowerCase() == "video") {
          header.parameters.push({
            type: "VIDEO",
            video: {
              // "id": formData.header[index].media
              link: formData.header[index].media,
            },
          });
          cardComp.push(header);
        }

        /*        
        if (data?.body.length > 0) {
          body.parameters = formData.body.map((h: any) => ({
            type: "text",
            text: h.text,
          }));
          components.push(body);
        }
  
        if (data?.buttons.length > 0) {
          formData?.buttons.map((h: any,i:number) => 
          {
            if(data.buttons[i].sub_type=="COPY_CODE"){
              components.push({
                type: "button",
                sub_type:data.buttons[i].sub_type,
                index:i,
                parameters: [{type:"coupon_code","coupon_code":h.text}],
              });
            }else{
              components.push({
                type: "button",
                sub_type:data.buttons[i].sub_type,
                index:i,
                parameters: [{type:"text","text":h.text}],
              });
            }
            return;        
          });
        } */
        cards.push({ card_index: index, components: cardComp });
        // console.log('cardComp',cardComp);
        // console.log('cards',cards);
        // console.log('components',components);
      });
      components.push({ type: "CAROUSEL", cards: cards });
    } else {
      if (data?.header.length > 0) {
        header.parameters = data?.header.map((h: any, index: any) => {
          const body: any = { type: h.type };
          if (h.type === "text") {
            body.text = formData.header[index].text;
          } else {
            body[h.type] = {
              link: formData?.header[index].media,
            };
            if (h.type === "document") {
              body[h.type] = {
                link: formData?.header[index].media,
                filename: url.getFilename(`${formData.header[index].media}`),
              };
            }
          }
          return body;
        });
        components.push(header);
      }
      if (templateType == "lto") {
        template.components.forEach((v: any) => {
          if (
            v.type.toUpperCase() == "LIMITED_TIME_OFFER" &&
            v.limited_time_offer.has_expiration == true
          ) {
            const lto: any = {
              type: "limited_time_offer",
              parameters: [
                {
                  type: "limited_time_offer",
                  limited_time_offer: {
                    expiration_time_ms: formData.lto_expiration_time,
                  },
                },
              ],
            };
            components.push(lto);
          }
        });
      }

      if (data?.body.length > 0) {
        body.parameters = formData.body.map((h: any) => ({
          type: "text",
          text: h.text,
        }));
        components.push(body);
      }
      if (data?.footer.length > 0) {
        footer.parameters = formData?.footer.map((h: any) => ({
          type: "text",
          text: h.text,
        }));
        components.push(footer);
      }

      if (data?.buttons.length > 0) {
        formData?.buttons.map((h: any, i: number) => {
          if (data.buttons[i].sub_type == "COPY_CODE") {
            components.push({
              type: "button",
              sub_type: data.buttons[i].sub_type,
              index: i,
              parameters: [{ type: "coupon_code", coupon_code: h.text }],
            });
          } else {
            components.push({
              type: "button",
              sub_type: data.buttons[i].sub_type,
              index: i,
              parameters: [{ type: "text", text: h.text }],
            });
          }
          return;
        });
      }
    }
    console.log("components>>", components);
    return components;
  },
  // getCarouselMessageBody this function is used set json to be sent in message
  getCarouselMessageBody: (template: any, formData: any, data: any) => {
    const bubblebody: any = { type: "body", parameters: [] };
    const components = [];

    console.log("template", template);
    console.log("formData", formData);
    console.log("data", data);

    if (data !== undefined) {
      if (data.body && data?.body.length > 0) {
        bubblebody.parameters = formData.body.map((h: any) => ({
          type: "text",
          text: h.text,
        }));
        components.push(bubblebody);
      }
    }
    template.components.forEach((comp: any) => {
      if (comp.type.toUpperCase() == "CAROUSEL") {
        const carousel: any = { type: "CAROUSEL", cards: [] };
        comp.cards.forEach((v: any, index: number) => {
          const card: any = {};
          console.log("card-----------", v, index);
          card.card_index = index;
          card.components = <any>[];

          v.components.forEach((cc: any) => {
            const header: any = { type: "HEADER", parameters: [] };
            // const body: any = { type: "body", parameters: [] };
            // const buttons:any={ type: "BUTTON",sub_type:"",index:0, parameters: [] };

            if (cc.type.toUpperCase() == "HEADER") {
              if (cc.format.toUpperCase() == "IMAGE") {
                header.parameters = {
                  type: "IMAGE",
                  image: { id: cc.example.header_handle[0] },
                };
              }

              if (cc.format.toUpperCase() == "VIDEO") {
                header.parameters = {
                  type: "VIDEO",
                  video: { id: cc.example.header_handle[0] },
                };
              }
              card.components.push(header);
            }
          });
          carousel.cards.push(card);
        });
        components.push(carousel);
      }
    });
    /* if (data?.header.length > 0) {
      header.parameters = data?.header.map((h: any, index: any) => {
        const body: any = { type: h.type };
        if (h.type === "text") {
          body.text = formData.header[index].text;
        } else {
          body[h.type] = {
            link: formData?.header[index].media,
          };
          if (h.type === "document") {
            body[h.type] = {
              link: formData?.header[index].media,
              filename: url.getFilename(`${formData.header[index].media}`),
            };
          }
        }
        return body;
      });
      components.push(header);
    } */

    /* 
    if (data?.buttons.length > 0) {
      formData?.buttons.map((h: any,i:number) => 
      {
        if(data.buttons[i].sub_type=="COPY_CODE"){
          components.push({
            type: "button",
            sub_type:data.buttons[i].sub_type,
            index:i,
            parameters: [{type:"coupon_code","coupon_code":h.text}],
          });
        }else{
          components.push({
            type: "button",
            sub_type:data.buttons[i].sub_type,
            index:i,
            parameters: [{type:"text","text":h.text}],
          });
        }
        return;        
      });
    } */
    return components;
  },
  // setComponentStructure & setCarouselComponentStructure function is used to send data to preview page to visualize the template/format use is creating

  setCarouselComponentStructure: (formData: any) => {
    let values = {
      bubble: <any>[],
      header: <any>[],
      body: <any>[],
      footer: <any>[],
      cards: <any>[],
    };
    let components = [];
    let cardVariableCount = 0;

    let carouselComp = {
      type: "CAROUSEL",
      cards: <any>[],
    };
    let bodyComp = {
      type: "BODY",
      text: formData.bubbleText,
    };

    /*  if (formData.headerType !==undefined && formData.headerType !=="NONE"){
      if(formData.headerType=="TEXT"){
          header = {
              ...header,
              "text":formData.headerText,
              "example": {
                  "header_text": [
                    formData?.headerExample
                  ]
              }
          }
          values.header[0] = {'text':formData?.headerExample}
      }else if(formData.headerType=="MEDIA"){
        header = {
          "text":"",
            "type": "HEADER",
            "format": formData.mediaType,
            "example": {
              "header_handle": [
              ]
            }
        }
      }
      components.push(header);
    } */

    if (
      formData.bubbleExample !== undefined &&
      formData?.bubbleExample?.length !== 0
    ) {
      let bubble_example_Arr = { example: {} };
      let arr = formData?.bubbleExample.split(",");
      bubble_example_Arr.example = { body_text: arr };
      bodyComp = {
        ...bodyComp,
        ...bubble_example_Arr,
      };

      for (let i = 0; i < arr.length; i++) {
        values.body[i] = { text: arr[i] };
      }
    }
    components.push(bodyComp);

    if (formData.cards != undefined) {
      formData.cards.map((v: any, i: number) => {
        let cardComponent: CarouselCardParameters[] = [];
        let headerObj = {
          type: "HEADER",
          format: v.mediaType,
          mediaFile: "",
        };
        if (v.file !== undefined) {
          headerObj.mediaFile = v.file;
        }
        cardComponent.push(headerObj);
        // console.log('v>>',v)
        if (v.body !== undefined && v.body.length > 0) {
          const bodyObj = {
            type: "BODY",
            text: v.body,
            example: { body_text: <any>[] },
          };
          if (v.bodyExample !== undefined && v.bodyExample.length > 0) {
            bodyObj.example.body_text = [v.bodyExample];

            let arr = v.bodyExample.split(",");
            values.cards[i] = { body: [] };
            for (let j = 0; j < arr.length; j++) {
              values.cards[i]["body"][j] = { text: arr[j] };
              cardVariableCount++;
            }
          }
          cardComponent.push(bodyObj);
        }

        if (
          v.buttonArray !== undefined &&
          v.buttonArray?.length > 0 &&
          v.buttons !== undefined
        ) {
          // console.log('v===+',v.buttons.website_btn[0])
          let btns = {} as any;
          for (let i = 0; i < Object.keys(v.buttonArray).length; i++) {
            let key = v.buttonArray[i].value;
            if (v.buttons[key] !== undefined) {
              btns[key] = v.buttons[key];
            }
          }
          let btnArray = { type: "buttons", buttonArray: btns };
          cardComponent.push(btnArray);
        }
        cardComponent.length > 0 &&
          carouselComp.cards.push({ components: cardComponent });
      });
      carouselComp.cards.length > 0 && components.push(carouselComp);
    }

    if (formData.footer != undefined && formData.footer.length > 0) {
      let footerComp = {
        type: "FOOTER",
        text: formData.footer,
      };
      components.push(footerComp);
    }
    return { components, values, cardVariableCount };
  },

  setComponentStructure: (formData: any) => {
    let values = { header: <any>[], body: <any>[], footer: <any>[] };
    let components = [];
    let header = {
      type: "HEADER",
      format: formData.headerType,
      text: "",
      example: {},
    };
    let bodyComp = {
      type: "BODY",
      text: formData.body,
    };
    if (formData.headerType !== undefined && formData.headerType !== "NONE") {
      if (formData.headerType == "TEXT") {
        header = {
          ...header,
          text: formData.headerText,
          example: {
            header_text: [formData?.headerExample],
          },
        };
        values.header[0] = { text: formData?.headerExample };
      } else if (formData.headerType == "MEDIA") {
        header = {
          text: "",
          type: "HEADER",
          format: formData.mediaType,
          example: {
            header_handle: [],
          },
        };
      }
      components.push(header);
    }

    if (
      formData.bodyExamples !== undefined &&
      formData?.bodyExamples?.length !== 0
    ) {
      let body_example_Arr = { example: {} };
      body_example_Arr.example = {
        body_text: [formData?.bodyExamples.split(",")],
      };
      bodyComp = {
        ...bodyComp,
        ...body_example_Arr,
      };

      let arr = formData?.bodyExamples.split(",");
      for (let i = 0; i < arr.length; i++) {
        values.body[i] = { text: arr[i] };
      }
    }

    components.push(bodyComp);

    if (formData.footer != undefined && formData.footer.length > 0) {
      let footerComp = {
        type: "FOOTER",
        text: formData.footer,
      };
      components.push(footerComp);
    }

    if (formData.ltoText != undefined && formData?.ltoText.length > 0) {
      let ltoComp = {
        type: "limited_time_offer",
        limited_time_offer: {
          text: formData.ltoText,
          has_expiration: formData.has_expiration,
        },
      };
      components.push(ltoComp);
    }

    if (
      formData.buttonArray !== undefined &&
      formData.buttonArray?.length > 0 &&
      formData.buttons !== undefined
    ) {
      let btns = {} as any;
      for (let i = 0; i < Object.keys(formData.buttonArray).length; i++) {
        let key = formData.buttonArray[i].value;
        if (formData.buttons[key] !== undefined) {
          btns[key] = formData.buttons[key];
        }
      }
      let btnArray = { type: "buttons", buttonArray: btns };
      components.push(btnArray);
    }
    return { components, values };
  },
  getTemplateType: (formData: string) => {
    const formdata = JSON.parse(formData);
    let type = "general";
    formdata.components.forEach((component: TemplateComponents) => {
      if (component.type.toUpperCase() == "CAROUSEL") {
        type = "carousel";
        return;
      }
      if (component.type.toUpperCase() == "LIMITED_TIME_OFFER") {
        type = "lto";
        return;
      }
    });
    return type;
  },

  // destructuring received template or format json to reset values in edit form

  destructureTemplate: (formData: string) => {
    const templateType = Templates.getTemplateType(formData);
    const formdata = JSON.parse(formData);
    let newObj = { ...defaultFormat, ...formdata, headerType: "NONE" };
    formdata.components.forEach((component: TemplateComponents, i: number) => {
      if (component.type == "HEADER") {
        if (
          component.format == "VIDEO" ||
          component.format == "Video" ||
          component.format == "IMAGE" ||
          component.format == "Image" ||
          component.format == "DOCUMENT" ||
          component.format == "Document"
        ) {
          newObj.headerType = "MEDIA";
          newObj.mediaType = component.format;
          newObj.headerHandle = component.example;
        } else if (component.format == "TEXT") {
          newObj.headerType = component.format;
          newObj.headerText = component?.text;
          // newObj.headerExample = component.example;
          newObj.headerExample = component.example?.header_text;
        } else {
          newObj.headerType = component.format;
        }
      }
      if (component.type == "BODY") {
        newObj.body = component.text;
        if (component.example !== undefined) {
          if (component.example.body_text.length > 0) {
            newObj.bodyExamples = component.example.body_text.join(",");
          } else {
            newObj.bodyExamples = component.example.body_text;
          }
        }
      }
      if (component.type == "FOOTER") {
        if (templateType !== "limited_time_offer") {
          newObj.footer = component.text;
        }
      }
      if (
        component.type.toLowerCase() == "limited_time_offer" &&
        templateType.toLowerCase() == "lto"
      ) {
        let offerComp = {
          type: "limited_time_offer",
          limited_time_offer: {
            text: component?.limited_time_offer?.text,
            has_expiration: component?.limited_time_offer?.has_expiration,
          },
        };
        newObj.offerComp = offerComp;
        newObj.ltoText = component?.limited_time_offer?.text;
        newObj.has_expiration = component?.limited_time_offer?.has_expiration;
      }
      if (component.type == "BUTTONS") {
        newObj.btn_count = component.buttons.length;
        let buttonArray: { value: string; label: string }[] = [];
        let buttons: any = {};
        let btnTypes: string[] = [];
        component.buttons.forEach((v, i) => {
          if (v.type == "URL") {
            if (!btnTypes.includes("URL")) {
              buttonArray.push({
                value: "website_btn",
                label: "visit Website",
              });
              btnTypes.push("URL");
              buttons.website_btn = [];
            }

            let obj: websiteInterface = {
              type: "URL",
              text: v.text,
              url_type: "static",
              website_url: v.url,
            };

            if (v.example) {
              obj.example = v.example.join(",");
            }
            buttons.website_btn.push(obj);
          }

          if (v.type == "PHONE_NUMBER") {
            buttonArray.push({ value: "call_btn", label: "Call Phone Number" });
            btnTypes.push("PHONE_NUMBER");
            buttons.call_btn = {
              type: v.type,
              text: v.text,
              country: "+91",
              //phone_number:v.phone_number
              phone_number: v.phone_number.slice(3),
            };
          }

          if (v.type == "COPY_CODE") {
            buttonArray.push({ value: "offer_btn", label: "Copy Offer Code" });
            btnTypes.push("COPY_CODE");
            buttons.offer_btn = {
              type: v.type,
              // example:v.example
              offer_code: v.example,
            };
          }
        });
        newObj.buttons = buttons;
        newObj.buttonArray = buttonArray;
      }
    });
    return { templateType, newObj };
  },
  destructureCarouselTemplate: (formData: string) => {
    const formdata = JSON.parse(formData);
    let newObj: CarouselTemplateRequiredParameters = {
      cards: [],
      name: formdata.name,
      language: formdata.language,
      category: formdata.category,
      bubbleText: "",
      bubbleExample: "",
    };
    // formdata.components.forEach((component:TemplateComponents)=>{
    formdata.components.forEach((component: any) => {
      if (component.type == "BODY") {
        newObj.bubbleText = component.text;
        if (component.example !== undefined) {
          if (component.example.body_text.length > 0) {
            newObj.bubbleExample = component.example.body_text.join(",");
          } else {
            newObj.bubbleExample = component.example.body_text;
          }
        }
      }
      if (component.type == "CAROUSEL") {
        component.cards &&
          component.cards.forEach((card: any) => {
            let cardObj = <cardsInterface>{};
            card.components.forEach((component: CarouselCardParameters) => {
              if (component.type == "HEADER") {
                component.format && (cardObj.mediaType = component.format);
                component.example &&
                  cardObj.example &&
                  (cardObj.example = component.example);
                component?.fileData && (cardObj.file = component.fileData);

                // cardObj.file
                // newObj.cards[i]['mediaType'] = component.format;
                // newObj.cards[1].headerHandle = component.example;
              }
              if (component.type == "BODY") {
                component.text && (cardObj.body = component.text);
                component.example &&
                  component.example.body_text &&
                  (cardObj.bodyExample = component.example.body_text.join(","));
              }
              if (component.type == "BUTTONS") {
                let buttonArray: { value: string; label: string }[] = [];
                let buttons: any = {};
                let btnTypes: string[] = [];

                component.buttons &&
                  component.buttons.forEach((v) => {
                    if (v.type == "URL") {
                      if (!btnTypes.includes("URL")) {
                        buttonArray.push({
                          value: "website_btn",
                          label: "visit Website",
                        });
                        btnTypes.push("URL");
                        buttons.website_btn = [];
                      }

                      let obj: websiteInterface = {
                        type: "URL",
                        text: v.text,
                        url_type: "static",
                        website_url: v.url,
                      };

                      if (v.example) {
                        obj.example = v.example.join(",");
                      }
                      buttons.website_btn.push(obj);
                    }
                    if (v.type == "PHONE_NUMBER") {
                      buttonArray.push({
                        value: "call_btn",
                        label: "Call Phone Number",
                      });
                      btnTypes.push("PHONE_NUMBER");
                      buttons.call_btn = {
                        type: v.type,
                        text: v.text,
                        country: "+91",
                        //phone_number:v.phone_number
                        phone_number: v.phone_number.slice(3),
                      };
                    }
                    if (v.type == "COPY_CODE") {
                      buttonArray.push({
                        value: "offer_btn",
                        label: "Copy Offer Code",
                      });
                      btnTypes.push("COPY_CODE");
                      buttons.offer_btn = {
                        type: v.type,
                        example: v.example,
                      };
                    }
                    if (v.type == "QUICK_REPLY") {
                      buttonArray.push({
                        value: "reply_btn",
                        label: "Reply Button",
                      });
                      btnTypes.push("QUICK_REPLY");
                      buttons.reply_btn = {
                        type: v.type,
                        example: v.example,
                      };
                    }
                  });
                cardObj.buttonArray = buttonArray;
                cardObj.buttons = buttons;
              }
            });
            newObj.cards.push(cardObj);
          });
      }
    });
    return { templateType: "carousel", newObj };
  },
  // json structure required to create template on whatsapp business account
  createTemplateJson: (formData: any, mode: string, type?: any) => {
    let components = [];
    let bodyComp = {
      type: "BODY",
      text: formData.body,
    };

    if (formData.bodyExamples.length !== 0) {
      let body_example_Arr = { example: {} };
      body_example_Arr.example = {
        body_text: [formData.bodyExamples.split(",")],
      };
      bodyComp = {
        ...bodyComp,
        ...body_example_Arr,
      };
    }

    if (formData.headerType !== "NONE") {
      type Org = { [key: string]: string | object };
      const header: Org = {};
      header.type = "HEADER";
      header.format = formData.headerType;

      if (formData.headerType == "TEXT") {
        header.text = formData.headerText;
        const expression = new RegExp(/{{[\d]{1,2}}}/gm, "g");
        const length = formData.headerText?.match(expression)?.length as number;
        if (length > 0 && length != undefined) {
          if (Array.isArray(formData?.headerExample)) {
            header.example = {
              header_text: formData?.headerExample,
            };
          } else {
            header.example = {
              header_text: [formData?.headerExample],
            };
          }
        }
      } else if (formData.headerType == "MEDIA") {
        header.format = formData.mediaType;
        if (
          formData.headerHandle !== undefined &&
          (formData.headerHandle.length > 0 ||
            Object.keys(formData.headerHandle).length > 0)
        ) {
          header.example = formData.headerHandle;
        }
      }
      components.push(header);
    }
    components.push(bodyComp);

    if (formData?.footer != undefined && formData?.footer.length > 0) {
      let footerComp = {
        type: "FOOTER",
        text: formData.footer,
      };
      components.push(footerComp);
    }

    if (
      formData?.buttonArray !== undefined &&
      formData?.buttonArray.length > 0
    ) {
      var size = formData?.buttonArray && formData?.buttonArray.length;

      if (size > 0) {
        const keys = Object.keys(formData.buttons);
        let btnComp = [];

        if (keys.includes("website_btn")) {
          for (let i = 0; i < formData.buttons.website_btn.length; i++) {
            if (formData.buttons.website_btn[i] !== undefined) {
              let webBtn = {};
              let param =
                formData.buttons.website_btn[i].website_url !== undefined
                  ? Templates.checkParamaterAndGetCount(
                      formData.buttons.website_btn[i].website_url
                    )
                  : 0;
              if (param > 0) {
                webBtn = {
                  type: formData.buttons.website_btn[i].type,
                  text: formData.buttons.website_btn[i].text,
                  url: formData.buttons.website_btn[i].website_url,
                  example: [formData.buttons.website_btn[i].example],
                };
              } else {
                webBtn = {
                  type: formData.buttons.website_btn[i].type,
                  text: formData.buttons.website_btn[i].text,
                  url: formData.buttons.website_btn[i].website_url,
                };
              }
              btnComp.push(webBtn);
            }
          }
        }

        if (keys.includes("call_btn")) {
          let phoneBtn = {
            type: formData.buttons.call_btn.type,
            text: formData.buttons.call_btn.text,
            phone_number:
              formData.buttons.call_btn.country +
              formData.buttons.call_btn.phone_number,
          };
          btnComp.push(phoneBtn);
        }

        if (keys.includes("offer_btn")) {
          let offerBtn = {
            // "type":formData.buttons.offer_btn.type,
            type: "COPY_CODE",
            example: formData.buttons.offer_btn.offer_code,
          };
          btnComp.push(offerBtn);
        }

        if (keys.includes("reply_btn")) {
          let replyBtn = {
            type: formData.buttons.reply_btn.type,
            text: formData.buttons.reply_btn.text,
          };
          btnComp.push(replyBtn);
        }
        components.push({ type: "BUTTONS", buttons: btnComp });
      }
    }

    let dataobj: any = {
      components: [...components],
    };

    if (mode == "edit") {
      if (type == "format") {
        return {
          name: formData.name.toLowerCase().replace(/ /g, "_"),
          language: formData.language,
          category: formData.category,
          json: JSON.stringify({
            name: formData.name.toLowerCase().replace(/ /g, "_"),
            language: formData.language,
            category: formData.category,
            ...dataobj,
          }),
        };
      } else {
        let result: any = {
          json: JSON.stringify(dataobj),
        };
        if (formData.status !== "APPROVED") {
          dataobj = { ...dataobj, category: formData.category };
          result = {
            ...result,
            category: formData.category,
            json: JSON.stringify(dataobj),
          };
        }
        return result;
      }
    } else if (mode == "add") {
      return {
        name: formData.name.toLowerCase().replace(/ /g, "_"),
        language: formData.language,
        category: formData.category,
        components: [...components],
      };
    }
  },
  createLTOTemplateJson: (formData: any, mode: string, type?: any) => {
    let components = [];

    if (formData.headerType !== "NONE") {
      type Org = { [key: string]: string | object };
      const header: Org = {};
      header.type = "HEADER";
      header.format = formData.headerType;

      if (formData.headerType == "TEXT") {
        header.text = formData.headerText;
        const expression = new RegExp(/{{[\d]{1,2}}}/gm, "g");
        const length = formData.headerText?.match(expression)?.length as number;
        if (length > 0 && length != undefined) {
          header.example = {
            header_text: [formData?.headerExample],
          };
        }
      } else if (formData.headerType == "MEDIA") {
        header.format = formData.mediaType;
        if (
          formData.headerHandle !== undefined &&
          (formData.headerHandle.length > 0 ||
            Object.keys(formData.headerHandle).length > 0)
        ) {
          header.example = formData.headerHandle;
        }
      }
      components.push(header);
    }

    let offerComp = {
      type: "limited_time_offer",
      limited_time_offer: {
        text: formData.ltoText,
        has_expiration: formData.has_expiration,
      },
    };
    components.push(offerComp);

    let bodyComp = {
      type: "BODY",
      text: formData.body,
    };

    if (formData.bodyExamples.length !== 0) {
      let body_example_Arr = { example: {} };
      body_example_Arr.example = {
        body_text: [formData.bodyExamples.split(",")],
      };
      bodyComp = {
        ...bodyComp,
        ...body_example_Arr,
      };
    }
    components.push(bodyComp);

    if (
      formData?.buttonArray !== undefined &&
      formData?.buttonArray.length > 0
    ) {
      var size = formData?.buttonArray && formData?.buttonArray.length;

      if (size > 0) {
        const keys = Object.keys(formData.buttons);
        let btnComp = [];

        if (keys.includes("offer_btn")) {
          let offerBtn = {
            // "type":formData.buttons.offer_btn.type,
            type: "COPY_CODE",
            example: formData.buttons.offer_btn.offer_code,
          };
          btnComp.push(offerBtn);
        }

        if (keys.includes("website_btn")) {
          for (let i = 0; i < formData.buttons.website_btn.length; i++) {
            if (formData.buttons.website_btn[i] !== undefined) {
              let webBtn = {};
              let param =
                formData.buttons.website_btn[i].website_url !== undefined
                  ? Templates.checkParamaterAndGetCount(
                      formData.buttons.website_btn[i].website_url
                    )
                  : 0;
              if (param > 0) {
                webBtn = {
                  type: formData.buttons.website_btn[i].type,
                  text: formData.buttons.website_btn[i].text,
                  url: formData.buttons.website_btn[i].website_url,
                  example: [formData.buttons.website_btn[i].example],
                };
              } else {
                webBtn = {
                  type: formData.buttons.website_btn[i].type,
                  text: formData.buttons.website_btn[i].text,
                  url: formData.buttons.website_btn[i].website_url,
                };
              }
              btnComp.push(webBtn);
            }
          }
        }

        if (keys.includes("call_btn")) {
          let phoneBtn = {
            type: formData.buttons.call_btn.type,
            text: formData.buttons.call_btn.text,
            phone_number:
              formData.buttons.call_btn.country +
              formData.buttons.call_btn.phone_number,
          };
          btnComp.push(phoneBtn);
        }
        if (keys.includes("reply_btn")) {
          let replyBtn = {
            type: formData.buttons.reply_btn.type,
            text: formData.buttons.reply_btn.text,
          };
          btnComp.push(replyBtn);
        }
        components.push({ type: "BUTTONS", buttons: btnComp });
      }
    }

    let dataobj: any = {
      components: [...components],
    };

    if (mode == "edit") {
      if (type == "format") {
        return {
          name: formData.name.toLowerCase().replace(/ /g, "_"),
          language: formData.language,
          category: formData.category,
          json: JSON.stringify({
            name: formData.name.toLowerCase().replace(/ /g, "_"),
            language: formData.language,
            category: formData.category,
            ...dataobj,
          }),
        };
      } else {
        let result: any = {
          json: JSON.stringify(dataobj),
        };
        if (formData.status !== "APPROVED") {
          dataobj = { ...dataobj, category: formData.category };
          result = {
            ...result,
            category: formData.category,
            json: JSON.stringify(dataobj),
          };
        }
        return result;
      }
    } else if (mode == "add") {
      console.log("final result return");
      console.table(formData);

      return {
        name: formData.name.toLowerCase().replace(/ /g, "_"),
        language: formData.language,
        category: formData.category,
        components: [...components],
      };
    }
  },

  createCarouselTemplateJson: async (formData: any, mode: string) => {
    let components = [];
    let bodyComp = {
      type: "BODY",
      text: formData.bubbleText,
    };
    if (formData.bubbleExample.length !== 0) {
      let body_example_Arr = { example: {} };
      body_example_Arr.example = {
        body_text: [formData.bubbleExample.split(",")],
      };
      bodyComp = {
        ...bodyComp,
        ...body_example_Arr,
      };
    }
    components.push(bodyComp);

    type SliderComp = {
      type: string;
      cards: {}[];
    };
    let sliderComp: SliderComp = {
      type: "CAROUSEL",
      cards: [],
    };

    if (formData.cards !== undefined) {
      await Promise.all(
        Object.values(formData.cards).map(async (card: any) => {
          type Org = { [key: string]: string | object };
          const header: Org = {};
          let body = { text: card.body };
          let btnComp = [];
          let cardcomp: any = [];

          //===header object============

          header.type = "HEADER";
          header.format = card.mediaType;
          header.fileData = { media: card.file };

          //===body object=====
          if (card.bodyExample !== undefined) {
            let example = { example: {} };
            example.example = { body_text: [card.bodyExample.split(",")] };
            body = {
              ...body,
              ...example,
            };
          }

          //===buttons object=======

          const keys = Object.keys(card.buttons);

          if (keys.includes("website_btn")) {
            for (let i = 0; i < card.buttons.website_btn.length; i++) {
              if (card.buttons.website_btn[i] !== undefined) {
                let webBtn = {};
                let param =
                  card.buttons.website_btn[i].website_url !== undefined
                    ? Templates.checkParamaterAndGetCount(
                        card.buttons.website_btn[i].website_url
                      )
                    : 0;
                if (param > 0) {
                  webBtn = {
                    type: card.buttons.website_btn[i].type,
                    text: card.buttons.website_btn[i].text,
                    url: card.buttons.website_btn[i].website_url,
                    example: [card.buttons.website_btn[i].example],
                  };
                } else {
                  webBtn = {
                    type: card.buttons.website_btn[i].type,
                    text: card.buttons.website_btn[i].text,
                    url: card.buttons.website_btn[i].website_url,
                  };
                }

                btnComp.push(webBtn);
              }
            }
          }

          if (keys.includes("call_btn")) {
            let phoneBtn = {
              type: card.buttons.call_btn.type,
              text: card.buttons.call_btn.text,
              phone_number:
                card.buttons.call_btn.country +
                card.buttons.call_btn.phone_number,
            };
            btnComp.push(phoneBtn);
          }

          if (keys.includes("offer_btn")) {
            let offerBtn = {
              type: "COPY_CODE",
              example: card.buttons.offer_btn.offer_code,
            };
            btnComp.push(offerBtn);
          }

          if (keys.includes("reply_btn")) {
            let replyBtn = {
              type: card.buttons.reply_btn.type,
              text: card.buttons.reply_btn.text,
            };
            btnComp.push(replyBtn);
          }
          if (card.file.length > 0) {
            cardcomp.push({ type: "HEADER", ...header });
          }
          cardcomp.push({ type: "BODY", ...body });
          cardcomp.push({ type: "BUTTONS", buttons: btnComp });
          sliderComp.cards.push({ components: [...cardcomp] });
        })
      );
    }
    components.push(sliderComp);

    // const dataobj = {
    //     "name": formData.name.toLowerCase().replace(/ /g,"_"),
    //     "language": formData.language,
    //     "category": formData.category,
    //     "components": [
    //       ...components
    //     ]
    // }
    // return dataobj;

    if (mode == "edit") {
      let dataobj: any = {
        components: [...components],
      };
      let result: any = {
        json: JSON.stringify(dataobj),
      };
      if (formData.status !== "APPROVED") {
        dataobj = { ...dataobj, category: formData.category };
        result = { category: formData.category, json: JSON.stringify(dataobj) };
      }
      return result;
    } else if (mode == "add") {
      return {
        name: formData.name.toLowerCase().replace(/ /g, "_"),
        language: formData.language,
        category: formData.category,
        allow_category_change: true,
        components: [...components],
      };
    }
  },
};
