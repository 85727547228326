import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { cloneDeep, debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Template, TemplateParameter, UploadData } from "types";

import AddMedia from "components/AddMedia";
import TemplateValue from "components/Bubbles/TemplateValue";
import Spinner from "components/Loader/Spinner";
import Modal from "components/Modal";
import config from "config";
import { Templates } from "helper/template";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import http from "utils/http";

interface Props {
  data: TemplateParameter;
  template: Template;
  loader: boolean;
  onClickHandle: Function;
  onBack: Function;
}

const Parameters = ({
  data,
  template,
  loader,
  onClickHandle,
  onBack,
}: Props): JSX.Element => {
  const cloneTemplate = cloneDeep(template);
  const count = Templates.checkParamater(cloneTemplate);
  const [media, setMedia] = useState<UploadData[] | []>([]);
  const [templateValue, setTemplateValue] = useState<{}>(count);
  const [showMediaModal, setShowMediaModal] = useState<boolean>(false);
  const [mediaLoader, setMediaLoader] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<TemplateParameter>();

  const goBack = () => {
    onBack(0);
  };

  const sendMessage = async (formData: TemplateParameter, e: any) => {
    e.preventDefault();

    const components = Templates.setComponentParameter2(formData, data,template);

    const dataTemplate = {
      type: "template",
      typeBody: {
        name: template.name,
        language: {
          code: template.language,
        },
        components,
      },
      category: template.category,
    }; 
    onClickHandle(dataTemplate);
  };
  useEffect(() => {
    (async () => {
      await getMedia();
    })();
    // eslint-disable-next-line
  }, []);

  const getMedia = async () => {
    if (data?.header[0]?.type || (data.cards && data.cards.length>0)){
      let url ;
      if(data?.header[0]?.type){
        url = `${config.endpoints.media.get}?isPaginate=false&type=${data.header[0].type}`;
      }else{
        url = `${config.endpoints.media.get}?isPaginate=false`;
      }
      try {
        const { data }: any = await http.get(url);
        const { medias } = data;
        setMedia(medias);
      } catch (error) {
        setMedia([]);
      }
    }
  };

  const onConfirmUpload = async (data: any) => {
    setMediaLoader(true);
    try {
      const url = config.endpoints.media.create;
      const formData = new FormData();
      if (data?.file) {
        formData.append("mimeType", "MEDIA");
        formData.append("name", data.name);
        formData.append("file", data.file);
      }
      await http.post(url, formData);
      await getMedia();
      setMediaLoader(false);
      setShowMediaModal(false);
      toast.success("New media added");
    } catch (err) {
      setMediaLoader(false);
      toast.error("Falied to add new media");
    }
  };

  const updateTemplateValue = (values: any) => {
    debouncedValue(values);
  };
  const debouncedValue = useRef(
    debounce((data) => setTemplateValue(data), 500)
  ).current;

  useEffect(() => {
    const subscription = watch((value) => updateTemplateValue(value));
    return () => subscription.unsubscribe();
  }, [watch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      debouncedValue.cancel();
    };
  }, [debouncedValue]);

  return (
    <>
      <h2 className="border-gray border-b p-3 text-lg font-semibold text-gray-600">
        Add Parameters
      </h2>
      <form onSubmit={handleSubmit(sendMessage)} noValidate>
        <div className="grid h-100 grid-cols-2 overflow-y-auto">
          <div className="border-r p-4">
            {data.header.length > 0 && (
              <div className="mb-4">
                <span>Header</span>
                {data.header.map((i, index) => (
                  <div key={`header-${index}`}>
                    {i.type === "text" && (
                      <>
                        <input
                          {...register(`header.${index}.text`, {
                            required: true,
                          })}
                          type="text"
                          name={`header.${index}.text`}
                          id={`header-${index}`}
                          placeholder={`Enter content for {{${index + 1}}}`}
                          className="mt-2 block w-full rounded-md border-gray-300 bg-white py-2 text-black  placeholder:text-slate-400 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                        />
                        {errors.header?.[index].text && (
                          <span className="text-sm text-red-500">
                            This field is required
                          </span>
                        )}
                      </>
                    )}
                    {i.type !== "text" && (
                      <div className="flex mt-2 space-x-2 items-center justify-between">
                        <div className="w-2/3 flex flex-col">
                          <select
                            defaultValue="DEFAULT"
                            className="block rounded-md border-gray-300 bg-white py-2 text-black  placeholder:text-slate-400 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                            {...register(`header.${index}.media`, {
                              required: true,
                            })}
                          >
                            <option value="DEFAULT" disabled>
                              Select media link
                            </option>
                            {media.map((m) => (
                              <option value={m.path} key={m.path}>
                                {m.name}
                              </option>
                            ))}
                          </select>
                          {errors.header?.[index].media && (
                            <span className="text-sm text-red-500">
                              This field is required
                            </span>
                          )}
                        </div>
                        <div className="flex w-1/3">
                          <button
                            className="w-full rounded-md border border-transparent bg-brand-800 px-4 py-2 text-sm font-medium text-white hover:bg-brand-900 focus:outline-none focus:ring-2 focus:ring-brand-800 focus:ring-offset-2"
                            type="button"
                            onClick={() => setShowMediaModal(true)}
                          >
                            Upload Media
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            {data.lto_expiration_time!==undefined && data.has_expiration && (
              <div className="mb-4">
                <span>Limited Time Offer Expiration Time in miliseconds</span>
                <div key={`lto_expiration_time`}>
                  <input
                    {...register(`lto_expiration_time`, {
                      required: true,
                    })}
                    type="text"
                    name={`lto_expiration_time`}
                    id={`lto_expiration_time`}
                    placeholder={`Enter Time in miliseconds`}
                    className="mt-2 block w-full rounded-md border-gray-300 bg-white py-2 text-black  placeholder:text-slate-400 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                  />
                  {errors.lto_expiration_time && (
                    <span className="text-sm text-red-500">
                      This field is required
                    </span>
                  )}
                </div>
              </div>
            )}
            {data.body.length > 0 && (
              <div className="mb-4">
                <span>Body</span>
                {data.body.map((i, index) => (
                  <div key={`body-${index}`}>
                    <input
                      {...register(`body.${index}.text`, {
                        required: true,
                      })}
                      type="text"
                      name={`body.${index}.text`}
                      id={`body-${index}`}
                      placeholder={`Enter content for {{${index + 1}}}`}
                      className="mt-2 block w-full rounded-md border-gray-300 bg-white py-2 text-black  placeholder:text-slate-400 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                    />
                    {errors.body?.[index].text && (
                      <span className="text-sm text-red-500">
                        This field is required
                      </span>
                    )}
                  </div>
                ))}
              </div>
            )}
            {data.footer.length > 0 && (
              <div className="mb-4">
                <span>Footer</span>
                {data.footer.map((i, index) => (
                  <div key={`footer-${index}`}>
                    <input
                      {...register(`footer.${index}.text`, {
                        required: true,
                      })}
                      type="text"
                      name={`footer.${index}.text`}
                      id={`footer-${index}`}
                      placeholder={`Enter content for {{${index + 1}}}`}
                      className="mt-2 block w-full rounded-md border-gray-300 bg-white py-2 text-black  placeholder:text-slate-400 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                    />
                    {errors.footer?.[index].text && (
                      <span className="text-sm text-red-500">
                        This field is required
                      </span>
                    )}
                  </div>
                ))}
              </div>
            )}
            {data.buttons.length > 0 && (
              <div className="mb-4">
                <span>Buttons</span>
                {data.buttons.map((i, index) => (
                  <div key={`buttons-${index}`}>
                    <input
                      {...register(`buttons.${index}.text`, {
                        required: true,
                      })}
                      type="text"
                      name={`buttons.${index}.text`}
                      id={`buttons-${index}`}
                      // placeholder={`Enter content for {{${index + 1}}}`}
                      placeholder={`Enter content for ${i.sub_type} {{${index + 1}}}`}
                      className="mt-2 block w-full rounded-md border-gray-300 bg-white py-2 text-black  placeholder:text-slate-400 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                    />
                    {errors.buttons?.[index].text && (
                      <span className="text-sm text-red-500">
                        This field is required
                      </span>
                    )}
                  </div>
                ))}
              </div>
            )}
            {/********** carousel inputs ************/}
            {data.cards && data.cards.length>0 && (
              <>
              <span className="mb-4"><b>Cards</b></span>
              {data.cards.map((i,index)=>(
                <div className="mb-4" key={`card${index}`}>
                <span>{`Card ${index+1}`}</span>
                  <div className="mb-4">
                    <span>Header</span>
                      <div key={`header-${index}`}>
                          <div className="flex mt-2 space-x-2 items-center justify-between">
                            <div className="w-2/3 flex flex-col">
                              <input type="hidden" {...register(`header.${index}.type`)} value={i.header.type} />
                              <select
                                defaultValue="DEFAULT"
                                className="block rounded-md border-gray-300 bg-white py-2 text-black  placeholder:text-slate-400 focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
                                {...register(`header.${index}.media`, {
                                  required: true,
                                })}
                              >
                                <option value="DEFAULT" disabled>
                                  Select media link
                                </option>
                                {media.map((m) => {
                                  if(i.header.type=="image" && (
                                    m.path?.endsWith(".jpg")||m.path?.endsWith(".JPG")||
                                    m.path?.endsWith(".jpeg")||m.path?.endsWith(".JPEG")||
                                    m.path?.endsWith(".PNG")||m.path?.endsWith(".png")
                                    )){
                                    return(
                                      <option value={m.path} key={m.path}>
                                        {m.name}
                                      </option>
                                    )
                                  }
                                  else if(i.header.type=="video" && (m.path?.endsWith(".mp4")||m.path?.endsWith(".MP4"))){
                                    return(
                                      <option value={m.path} key={m.path}>
                                        {m.name}
                                      </option>
                                    )
                                  }
                                  
                                }
                                )}
                              </select>
                              {errors.header?.[index].media && (
                                <span className="text-sm text-red-500">
                                  This field is required
                                </span>
                              )}
                            </div>
                            <div className="flex w-1/3">
                              <button
                                className="w-full rounded-md border border-transparent bg-brand-800 px-4 py-2 text-sm font-medium text-white hover:bg-brand-900 focus:outline-none focus:ring-2 focus:ring-brand-800 focus:ring-offset-2"
                                type="button"
                                onClick={() => setShowMediaModal(true)}
                              >
                                Upload Media
                              </button>
                            </div>
                          </div>
                      </div>
                  </div>
                </div>
              ))}
              </>
            )}
          </div>
          <div className="relative p-6">
            <div className="sample-space absolute inset-0"></div>
            <TemplateValue data={templateValue} template={template} />
          </div>
        </div>
        <div className="relative bottom-0 left-0 right-0 flex items-center justify-center rounded-b-2xl bg-brand p-4">
          <h5 className="text-sm font-semibold uppercase text-white">
            {template.name}
          </h5>
          <div className="absolute bottom-4 left-4 flex p-4">
            <button
              type="button"
              className="rounded-full bg-gray-500 p-2 shadow-md shadow-gray-500"
              onClick={goBack}
            >
              <ArrowLeftIcon className="h-6 w-6 text-white" />
            </button>
          </div>
          <div className="absolute bottom-4 right-4 z-10 flex p-4">
            <button
              type="submit"
              className="rounded-full bg-brand-600 p-2 shadow-md shadow-brand-800 disabled:opacity-25"
            >
              {loader ? (
                <Spinner />
              ) : (
                <ArrowRightIcon className="h-6 w-6 text-white" />
              )}
            </button>
          </div>
        </div>
      </form>
      <Modal
        size="max-w-lg"
        show={showMediaModal}
        onCloseModal={() => setShowMediaModal(false)}
      >
        <AddMedia onConfirm={onConfirmUpload} loader={mediaLoader} />
      </Modal>
    </>
  );
};
export default Parameters;
