export const MIMETYPE = [
  "image/png",
  "image/jpeg",
  "image/webp",
  "image/jpg",
  "application/pdf",
  "video/mp4",
];

export const IMAGEMIMETYPE = [
  "image/png",
  "image/jpeg",
  "image/webp",
  "image/jpg",
];


export const VIDEOMIMETYPE = [
  "video/mp4",
];

export const DOCMIMETYPE = [
  "application/pdf",
];


export const MIMEEXCELTYPE = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
