import {
  BriefcaseIcon,
  ChatBubbleBottomCenterTextIcon,
  CheckBadgeIcon,
  CircleStackIcon,
  Cog6ToothIcon,
  CogIcon,
  CubeIcon,
  CurrencyRupeeIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  FolderIcon,
  HomeIcon,
  LifebuoyIcon,
  MegaphoneIcon,
  PhotoIcon,
  ShoppingCartIcon,
  UserCircleIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

import { ROLES } from "./Role";

export const Navigation: any = {
  SUPERADMIN: [
    {
      name: "Dashboard",
      href: `/${ROLES["SUPERADMIN"]}/dashboard`,
      icon: <HomeIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["SUPERADMIN"]}/dashboard`],
    },
    {
      name: "Clients",
      href: `/${ROLES["SUPERADMIN"]}/clients`,
      icon: <UsersIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["SUPERADMIN"]}/clients`],
    },
    {
      name: "System Logs",
      href: `/${ROLES["SUPERADMIN"]}/system-logs`,
      icon: <ExclamationTriangleIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["SUPERADMIN"]}/system-logs`],
    },
    {
      name: "Message Logs",
      href: `/${ROLES["SUPERADMIN"]}/message-logs`,
      icon: <ExclamationCircleIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["SUPERADMIN"]}/message-logs`],
    },
    {
      name: "Modules",
      href: `/${ROLES["SUPERADMIN"]}/modules`,
      icon: <CubeIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["SUPERADMIN"]}/modules`],
    },
    {
      name: "Deductions",
      href: `/${ROLES["SUPERADMIN"]}/deductions`,
      icon: <CurrencyRupeeIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["SUPERADMIN"]}/deductions`],
    },
    {
      name: "Media",
      href: `/${ROLES["SUPERADMIN"]}/media`,
      icon: <PhotoIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["SUPERADMIN"]}/media`],
    },
    {
      name: "Template Formats",
      href: `/${ROLES["SUPERADMIN"]}/template-formats`,
      icon: <FolderIcon className="h-6 w-6" />,
      activeUrl: [
        `/${ROLES["SUPERADMIN"]}/template-formats`,
        `/${ROLES["SUPERADMIN"]}/template-formats`,
      ],
    },
    {
      name: "Settings",
      href: `/${ROLES["SUPERADMIN"]}/settings`,
      icon: <Cog6ToothIcon className="h-6 w-6" />,
      activeUrl: [
        `/${ROLES["SUPERADMIN"]}/settings`,
        `/${ROLES["SUPERADMIN"]}/settings/profile`,
        `/${ROLES["SUPERADMIN"]}/settings/account`,
      ],
    },
  ],
  ADMIN: [
    {
      name: "Dashboard",
      href: `/${ROLES["ADMIN"]}/dashboard`,
      icon: <HomeIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["ADMIN"]}/dashboard`],
    },
    {
      name: "Clients",
      href: `/${ROLES["ADMIN"]}/clients`,
      icon: <UsersIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["ADMIN"]}/clients`],
    },
    {
      name: "Settings",
      href: `/${ROLES["ADMIN"]}/settings`,
      icon: <Cog6ToothIcon className="h-6 w-6" />,
      activeUrl: [
        `/${ROLES["ADMIN"]}/settings`,
        `/${ROLES["ADMIN"]}/settings/profile`,
        `/${ROLES["ADMIN"]}/settings/account`,
      ],
    },
    {
      name: "Formats",
      href: `/${ROLES["SUPERADMIN"]}/formats`,
      icon: <FolderIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["SUPERADMIN"]}/formats`],
    },
  ],
  CLIENT: [
    {
      name: "Dashboard",
      href: `/${ROLES["CLIENT"]}/dashboard`,
      icon: <HomeIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/dashboard`],
    },
    {
      name: "Messages",
      href: `/${ROLES["CLIENT"]}/messages`,
      icon: <ChatBubbleBottomCenterTextIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/messages`],
    },
    {
      name: "Campaigns",
      href: `/${ROLES["CLIENT"]}/campaigns`,
      icon: <MegaphoneIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/campaigns`],
    },
    // {
    //   name: "Bulk Message",
    //   href: `/${ROLES["CLIENT"]}/bulk-message`,
    //   icon: <InboxStackIcon className="h-6 w-6" />,
    //   activeUrl: [`/${ROLES["CLIENT"]}/bulk-message`],
    // },
    {
      name: "Recipients",
      href: `/${ROLES["CLIENT"]}/recipients`,
      icon: <UserGroupIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/recipients`],
    },
    {
      name: "Approvals",
      href: `/${ROLES["CLIENT"]}/approvals`,
      icon: <CheckBadgeIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/approvals`],
    },
    {
      name: "Media",
      href: `/${ROLES["CLIENT"]}/media`,
      icon: <PhotoIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/media`],
    },
    {
      name: "Users",
      href: `/${ROLES["CLIENT"]}/users`,
      icon: <UserGroupIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/users`],
    },
    {
      name: "Collections",
      href: `/${ROLES["CLIENT"]}/collections`,
      icon: <CircleStackIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/collections`],
    },
    {
      name: "Orders",
      href: `/${ROLES["CLIENT"]}/orders`,
      icon: <ShoppingCartIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/orders`],
    },
    {
      name: "Templates",
      href: `/${ROLES["CLIENT"]}/templates`,
      icon: <FolderIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/templates`],
    },
    {
      name: "Settings",
      href: `/${ROLES["CLIENT"]}/settings`,
      icon: <Cog6ToothIcon className="h-6 w-6" />,
      activeUrl: [
        `/${ROLES["CLIENT"]}/settings`,
        `/${ROLES["CLIENT"]}/settings/profile`,
        `/${ROLES["CLIENT"]}/settings/account`,
        `/${ROLES["CLIENT"]}/settings/business`,
      ],
    },
    {
      name: "Formats",
      href: `/${ROLES["CLIENT"]}/formats`,
      icon: <FolderIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/formats`],
    },
  ],
  EMPLOYEE: [
    {
      name: "Dashboard",
      href: `/${ROLES["CLIENT"]}/dashboard`,
      icon: <HomeIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/dashboard`],
    },
    {
      name: "Messages",
      href: `/${ROLES["CLIENT"]}/messages`,
      icon: <ChatBubbleBottomCenterTextIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/messages`],
    },
    {
      name: "Campaigns",
      href: `/${ROLES["CLIENT"]}/campaigns`,
      icon: <MegaphoneIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/campaigns`],
    },
    // {
    //   name: "Bulk Message",
    //   href: `/${ROLES["CLIENT"]}/bulk-message`,
    //   icon: <InboxStackIcon className="h-6 w-6" />,
    //   activeUrl: [`/${ROLES["CLIENT"]}/bulk-message`],
    // },
    {
      name: "Recipients",
      href: `/${ROLES["CLIENT"]}/recipients`,
      icon: <UserGroupIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/recipients`],
    },
    {
      name: "Media",
      href: `/${ROLES["CLIENT"]}/media`,
      icon: <PhotoIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/media`],
    },
    {
      name: "Users",
      href: `/${ROLES["CLIENT"]}/users`,
      icon: <UserGroupIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/users`],
    },
    {
      name: "Collections",
      href: `/${ROLES["CLIENT"]}/collections`,
      icon: <CircleStackIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/collections`],
    },
    {
      name: "Orders",
      href: `/${ROLES["CLIENT"]}/orders`,
      icon: <ShoppingCartIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/orders`],
      beta: true,
    },
    {
      name: "Settings",
      href: `/${ROLES["CLIENT"]}/settings`,
      icon: <Cog6ToothIcon className="h-6 w-6" />,
      activeUrl: [
        `/${ROLES["CLIENT"]}/settings`,
        `/${ROLES["CLIENT"]}/settings/profile`,
        `/${ROLES["CLIENT"]}/settings/account`,
        `/${ROLES["CLIENT"]}/settings/business`,
      ],
    },
  ],
};

export const SettingNavigation: any = {
  SUPERADMIN: [
    {
      name: "Profile",
      href: `/${ROLES["SUPERADMIN"]}/settings/profile`,
      icon: <UserCircleIcon className="h-6 w-6" />,
      activeUrl: [
        `/${ROLES["SUPERADMIN"]}/settings/profile`,
        `/${ROLES["SUPERADMIN"]}/settings`,
      ],
    },
  ],
  ADMIN: [
    {
      name: "Profile",
      href: `/${ROLES["ADMIN"]}/settings/profile`,
      icon: <UserCircleIcon className="h-6 w-6" />,
      activeUrl: [
        `/${ROLES["ADMIN"]}/settings/profile`,
        `/${ROLES["ADMIN"]}/settings`,
      ],
    },
  ],
  CLIENT: [
    {
      name: "Profile",
      href: `/${ROLES["CLIENT"]}/settings/profile`,
      icon: <UserCircleIcon className="h-6 w-6" />,
      activeUrl: [
        `/${ROLES["CLIENT"]}/settings/profile`,
        `/${ROLES["CLIENT"]}/settings`,
      ],
    },
    {
      name: "Account Settings",
      href: `/${ROLES["CLIENT"]}/settings/account`,
      icon: <CogIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/settings/account`],
    },
    {
      name: "Business Account",
      href: `/${ROLES["CLIENT"]}/settings/business`,
      icon: <BriefcaseIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/settings/business`],
    },
    {
      name: "Customer Support",
      href: `/${ROLES["CLIENT"]}/settings/customer-support`,
      icon: <LifebuoyIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/settings/customer-support`],
    },
  ],
  EMPLOYEE: [
    {
      name: "Profile",
      href: `/${ROLES["CLIENT"]}/settings/profile`,
      icon: <UserCircleIcon className="h-6 w-6" />,
      activeUrl: [
        `/${ROLES["CLIENT"]}/settings/profile`,
        `/${ROLES["CLIENT"]}/settings`,
      ],
    },
    {
      name: "Customer Support",
      href: `/${ROLES["CLIENT"]}/settings/customer-support`,
      icon: <LifebuoyIcon className="h-6 w-6" />,
      activeUrl: [`/${ROLES["CLIENT"]}/settings/customer-support`],
    },
  ],
};
