import {
  BusinessAccount,
  CampaignData,
  ClientData,
  ClientUserData,
  CollectionData,
  CollectionItemData,
  Credit,
  Deductions,
  Filter,
  FormatData,
  IDashboard,
  ITransition,
  Media,
  Modules,
  OptionsData,
  OrderData,
  OrderItem,
  Pagination,
  PricingData,
  RecipientsData,
  SupportData,
  Template,
  TemplateComponents,
  TemplateParameter,
  LTOTemplateParameter,
  Transaction,
  User,
  buttons,
  CarouselTemplateParameter,
  cursorBasedPaginate,
  TemplateFilter,
} from "types";

import { PRICING } from "./Pricing";

export const initUser: User = {
  id: "",
  name: "",
  email: "",
  phone: "",
  role: "",
  is_active: false,
};

export const defaultPagination: Pagination = {
  totalItems: 0,
  currentPage: 1,
  totalPages: 0,
  startItem: 0,
  endItem: 0,
};

export const defaultLoader = [1, 2, 3];

export const defaultFilter: Filter = {
  searchTerm: "",
  searchLabel: [],
  searchByReadUnread: "ALL",
  isPaginate: true,
};

export const defaultCredit: Credit = {
  balance: 0.0,
  clientId: "",
};
export const defaultBusinessAccount: BusinessAccount = {
  accountId: "",
  id: "",
  phoneNoId: "",
  token: "",
  limit: 0,
  appId: "",
  accountType: "DIRECT",
};

export const defaultPricing: PricingData = {
  id: "",
  clientId: "",
  marketing: PRICING.marketing,
  authentication: PRICING.authentication,
  utility: PRICING.utility,
  service: PRICING.service,
  createdAt: new Date(),
  updatedAt: new Date(),
};

export const defaultUser: User = {
  id: "",
  email: "",
  name: "",
  phone: "",
  password: "",
  role: "",
  is_active: true,
};

export const defaultClient: ClientData = {
  id: "",
  isActive: true,
  organizationEmail: "",
  organizationName: "",
  waPhone: "",
  type: "",
  User: initUser,
  Credit: defaultCredit,
  BusinessAccount: defaultBusinessAccount,
  Pricing: defaultPricing,
};

export const defaultClientUser: ClientUserData = {
  id: "",
  clientId: "",
  User: defaultUser,
  Client: defaultClient,
  isActive: true,
};

export const defaultRecipient: RecipientsData = {
  id: "",
  name: "",
  phone: "",
  lastMessageTime: new Date(),
  RecipientTagMaps: [],
  totalUnreadMessage: 0,
  lastMessage: "",
  tags: [],
};

export const defaultTransaction: Transaction = {
  transactionType: "",
  messageId: "",
  clientId: "",
  balance: 0.0,
  amount: 0.0,
  category: "",
  Client: defaultClient,
  Recipient: defaultRecipient,
  createdAt: new Date(),
};

export const defaultTemplate: Template = {
  name: "",
  components: [],
  language: "",
  status: "",
  category: "",
  id: "",
};

export const defaultTemplateComponent: TemplateComponents = {
  type: "",
  format: "",
  text: "",
  image: { link: "" },
  document: { link: "" },
  video: { link: "" },
  parameters: [],
  buttons: [],
};

export const defaultMedia: Media = {
  link: "",
};

export const defaultTemplateParams: TemplateParameter = {
  header: [],
  body: [],
  footer: [],
  buttons: [],
  cards: [],
  lto_expiration_time: "",
  has_expiration: false,
  // cards:<any>[],
  totalCount: 0,
  template: defaultTemplate,
};

export const carouselDefaultTemplateParams: CarouselTemplateParameter = {
  cards: [],
  body: [],
  buttons: [],
  totalCount: 0,
  template: defaultTemplate,
};

export const LtoDefaultTemplateParams: LTOTemplateParameter = {
  header: [],
  body: [],
  ltoOffer: [],
  buttons: [],
  buttonArray: [
    {
      value: "website_btn",
      label: "visit Website",
    },
  ],
  totalCount: 0,
  template: defaultTemplate,
};

export const defaultCampaign: CampaignData = {
  id: "",
  name: "",
  notes: "",
  template: defaultTemplate,
  recipients: [],
  selectOption: "",
  templateParameter: defaultTemplateParams,
  createdAt: new Date(),
  updatedAt: new Date(),
};

export const defaultOptions: OptionsData = {
  label: "",
  value: "",
};

export const initDashboard: IDashboard = {
  marketing: { count: 0, rows: [{ amount: 0 }] },
  utility: { count: 0, rows: [{ amount: 0 }] },
  authentication: { count: 0, rows: [{ amount: 0 }] },
  service: { count: 0, rows: [{ amount: 0 }] },
};

export const defaultModule: Modules = {
  id: "",
  description: "",
  key: "",
  active: false,
  createdAt: new Date(),
  updatedAt: new Date(),
};

export const initRightTransition: ITransition = {
  enterFrom: "-right-20",
  leaveFrom: "right-20",
};
export const initScaleTransition: ITransition = {
  enterFrom: "scale-95",
  leaveFrom: "scale-100",
};

export const defaultCollectionItems: CollectionItemData = {
  collectionId: "",
  id: "",
  isActive: true,
  name: "",
  stock: 0,
  createdAt: new Date(),
  updatedAt: new Date(),
};

export const defaultCollection: CollectionData = {
  id: "",
  name: "",
  createdAt: new Date(),
  updatedAt: new Date(),
  clientId: "",
  isActive: true,
  CollectionItems: [defaultCollectionItems],
};

const defaultOrderItems: OrderItem = {
  id: "",
  orderId: "",
  isActive: false,
  itemId: "",
  quantity: 0,
  Collection: defaultCollection,
  CollectionItem: defaultCollectionItems,
  createdAt: new Date(),
  updatedAt: new Date(),
};

export const deafultOrder: OrderData = {
  clientId: "",
  id: "",
  recipinetId: "",
  Recipient: defaultRecipient,
  isActive: true,
  createdAt: new Date(),
  updatedAt: new Date(),
  OrderItems: [defaultOrderItems],
};

export const defaultSupport: SupportData = {
  clientId: "",
  id: "",
  name: "",
  phone: "",
  createdAt: new Date(),
  updatedAt: new Date(),
};

//const btn:buttons=['call_btn'];

export const defaultFormat: FormatData = {
  id: "",
  name: "",
  language: "en_US",
  category: "",
  body: "",
  bodyExamples: "",
  json: "",
  headerType: "",
  headerText: "",
  headerExample: "",
  mediaType: "",
  media: "",
  footer: "",
  status: "",
  buttons: [],
  btn_count: 0,
  buttonArray: [],
  previewImage: "",
  createdAt: new Date(),
  updatedAt: new Date(),
};

export const cursorBasedPagination: cursorBasedPaginate = {
  cursors: { after: "", before: "" },
  next: "",
};

export const templateFilter: TemplateFilter = {
  status: "",
  category: "",
  name: "",
};

export const defaultDeduction: Deductions = {
  id: "",
  description: "",
  code: "",
  createdAt: new Date(),
  updatedAt: new Date(),
};
